import { createTheme } from '@mui/material';
// import '@fontsource-variable/lora';
// import './fonts/Nexa.css'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 900,
      lg: 1200,
      xl: 1600,
      xxl: 1940,
      xxxl: 2200,
    }},
  typography: {
    fontFamily: 'Nexa',
  },
  palette: {
    primary: {
      //GREY
      main: '#343434'
      //original
      //main: '#203740',
    },
    secondary: {
      //main: '#FFFFFF'
      //original
      main: '#DFCFA7',
    },
  },
},
);

export default theme;
