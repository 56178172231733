export default function PatientIntakeForm() {

  return (
    <form id="ppi-form" action="https://app.webpt.com/intake/index/setintake/key/C3cflFaFsPo%25253D/ac/22873cda3611f78b42602726fbed1cc06ab630857929da971c3415d63aa691cb" method="POST" autocomplete="off" style={{dsiplay: 'flex'}}>
      <fieldset id="ppi-fieldset-0" class="ppi-fieldset" style={{borderRadius:"4px"}}>
        <legend class="ppi-legend">Patient Information</legend>
        <table id="ppi-table-0" class="ppi-table" cellpadding="4" cellspacing="0" style={{width: "100%", border: "0px"}}>
          <tbody>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Title</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Title" class="ppi-field-container" style={{display: "inline-block"}}>
                  <select id="ppi-data-Title" name="Title" class="ppi-field-select" size="1">
                    <option value="">
                    </option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Miss">Miss</option>
                    <option value="Dr.">Dr.</option>
                  </select>
                </div>
                <div id="ppi-fieldrequired-container-0" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>First Name</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-FirstName" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-FirstName" name="FirstName" class="ppi-field-input-text" type="text" size="20" maxlength="64" required="" />
                </div>
                <div id="ppi-fieldrequired-container-1" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Middle Name</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-MiddleName" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-MiddleName" name="MiddleName" class="ppi-field-input-text" type="text" size="20" maxlength="64" />
                </div>
                <div id="ppi-fieldrequired-container-2" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Last Name</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-LastName" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-LastName" name="LastName" class="ppi-field-input-text" type="text" size="20" maxlength="64" required="" />
                </div>
                <div id="ppi-fieldrequired-container-3" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Preferred Name</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Nickname" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-Nickname" name="Nickname" class="ppi-field-input-text" type="text" size="20" maxlength="64" />
                </div>
                <div id="ppi-fieldrequired-container-4" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Gender</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Sex" class="ppi-field-container" style={{display: "inline-block"}}>
                  <select id="ppi-data-Sex" name="Sex" class="ppi-field-select" size="1" required="">
                    <option value="">
                    </option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>
                <div id="ppi-fieldrequired-container-5" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Date of Birth (MM DD YYYY)</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-BirthDateMonth" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-BirthDateMonth" name="BirthDateMonth" class="ppi-field-input-text" type="text" size="2" maxlength="2" required="" />
                </div>
                <div id="ppi-field-container-BirthDateDay" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-BirthDateDay" name="BirthDateDay" class="ppi-field-input-text" type="text" size="2" maxlength="2" required="" />
                </div>
                <div id="ppi-field-container-BirthDateYear" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-BirthDateYear" name="BirthDateYear" class="ppi-field-input-text" type="text" size="4" maxlength="4" required="" />
                </div>
                <div id="ppi-fieldrequired-container-6" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <fieldset id="ppi-fieldset-1" class="ppi-fieldset" style={{borderRadius:"4px"}}>
        <legend class="ppi-legend">Address</legend>
        <table id="ppi-table-1" class="ppi-table" cellpadding="4" cellspacing="0" style={{width: "100%", border: "0px"}}>
          <tbody>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Type</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Adrb_AddressType0190ID" class="ppi-field-container" style={{display: "inline-block"}}>
                  <select id="ppi-data-Adrb_AddressType0190ID" name="Adrb_AddressType0190ID" class="ppi-field-select" size="1" required="">
                    <option value="">
                    </option>
                    <option value="H">Home</option>
                    <option value="BI">Billing Address</option>
                  </select>
                </div>
                <div id="ppi-fieldrequired-container-0" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Street</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Adrb_AddressStreet" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-Adrb_AddressStreet" name="Adrb_AddressStreet" class="ppi-field-input-text" type="text" size="20" maxlength="64" required="" />
                </div>
                <div id="ppi-fieldrequired-container-1" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Other Designation</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Adrb_AddressOtherDesignation" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-Adrb_AddressOtherDesignation" name="Adrb_AddressOtherDesignation" class="ppi-field-input-text" type="text" size="20" maxlength="64" />
                </div>
                <div id="ppi-fieldrequired-container-2" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>City</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Adrb_AddressCity" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-Adrb_AddressCity" name="Adrb_AddressCity" class="ppi-field-input-text" type="text" size="20" maxlength="45" required="" />
                </div>
                <div id="ppi-fieldrequired-container-3" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>State/Province</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Adrb_AddressStateProvince" class="ppi-field-container" style={{display: "inline-block"}}>
                  <select id="ppi-data-Adrb_AddressStateProvince" name="Adrb_AddressStateProvince" class="ppi-field-select" required="">
                    <option value="">
                    </option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DC">District of Columbia</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div id="ppi-fieldrequired-container-4" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Country</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Adrb_AddressCountry3166ID" class="ppi-field-container" style={{display: "inline-block"}}>
                  <select id="ppi-data-Adrb_AddressCountry3166ID" name="Adrb_AddressCountry3166ID" class="ppi-field-select" required="" style={{width: '100%', maxwidth: '80px'}}>
                    <option value="">
                    </option>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                    <option value="MX">Mexico</option>
                    <option value="AF">Afghanistan</option>
                    <option value="AX">Aland Islands</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AS">American Samoa</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AQ">Antarctica</option>
                    <option value="AG">Antigua And Barbuda</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermuda</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">Bolivia</option>
                    <option value="BA">Bosnia And Herzegovina</option>
                    <option value="BW">Botswana</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BR">Brazil</option>
                    <option value="IO">British Indian Ocean Territory</option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>
                    <option value="CV">Cape Verde</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="CF">Central African Republic</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CC">Cocos (Keeling) Islands</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo</option>
                    <option value="CD">Congo, The Democratic Republic Of The</option>
                    <option value="CK">Cook Islands</option>
                    <option value="CR">Costa Rica</option>
                    <option value="CI">Cote D'ivoire</option>
                    <option value="HR">Croatia</option>
                    <option value="CU">Cuba</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GF">French Guiana</option>
                    <option value="PF">French Polynesia</option>
                    <option value="TF">French Southern Territories</option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GR">Greece</option>
                    <option value="GL">Greenland</option>
                    <option value="GD">Grenada</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="HM">Heard Island And Mcdonald Islands</option>
                    <option value="VA">Holy See (Vatican City State)</option>
                    <option value="HN">Honduras</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IR">Iran, Islamic Republic Of</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KP">Korea, Democratic People's Republic Of</option>
                    <option value="KR">Korea, Republic Of</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LA">Lao People's Democratic Republic</option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libyan Arab Jamahiriya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MO">Macao</option>
                    <option value="MK">Macedonia, The Former Yugoslav Republic Of</option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="YT">Mayotte</option>
                    <option value="FM">Micronesia, Federated States Of</option>
                    <option value="MD">Moldova, Republic Of</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="MS">Montserrat</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="AN">Netherlands Antilles</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PS">Palestinian Territory, Occupied</option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Reunion</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda</option>
                    <option value="SH">Saint Helena</option>
                    <option value="KN">Saint Kitts And Nevis</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="PM">Saint Pierre And Miquelon</option>
                    <option value="VC">Saint Vincent And The Grenadines</option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="ST">Sao Tome And Principe</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="CS">Serbia And Montenegro</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="GS">South Georgia And The South Sandwich Islands</option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SJ">Svalbard And Jan Mayen</option>
                    <option value="SZ">Swaziland</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syrian Arab Republic</option>
                    <option value="TW">Taiwan, Province Of China</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">Tanzania, United Republic Of</option>
                    <option value="TH">Thailand</option>
                    <option value="TL">Timor-Leste</option>
                    <option value="TG">Togo</option>
                    <option value="TK">Tokelau</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad And Tobago</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TC">Turks And Caicos Islands</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>
                    <option value="UM">United States Minor Outlying Islands</option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VE">Venezuela</option>
                    <option value="VN">Viet Nam</option>
                    <option value="VG">Virgin Islands, British</option>
                    <option value="VI">Virgin Islands, U.S.</option>
                    <option value="WF">Wallis And Futuna</option>
                    <option value="EH">Western Sahara</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>
                <div id="ppi-fieldrequired-container-5" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Postal Code</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Adrb_AddressPostalCode" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-Adrb_AddressPostalCode" name="Adrb_AddressPostalCode" class="ppi-field-input-text" type="text" size="10" maxlength="20" required="" />
                </div>
                <div id="ppi-fieldrequired-container-6" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <fieldset id="ppi-fieldset-2" class="ppi-fieldset" style={{borderRadius:"4px"}}>
        <legend class="ppi-legend">Contact</legend>
        <table id="ppi-table-2" class="ppi-table" cellpadding="4" cellspacing="0" style={{width: "100%", border: "0px"}}>
          <tbody>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Email Address</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-EmailAddress" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-EmailAddress" name="EmailAddress" class="ppi-field-input-text" type="text" size="20" maxlength="64" />
                </div>
                <div id="ppi-fieldrequired-container-0" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Home Phone</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-HomeTelephoneAreaCode" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-HomeTelephoneAreaCode" name="HomeTelephoneAreaCode" class="ppi-field-input-text" type="text" size="3" maxlength="3" />
                </div>
                <div id="ppi-field-container-HomeTelephoneLocalNumber1" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-HomeTelephoneLocalNumber1" name="HomeTelephoneLocalNumber1" class="ppi-field-input-text" type="text" size="3" maxlength="3" />
                </div>
                <div id="ppi-field-container-HomeTelephoneLocalNumber2" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-HomeTelephoneLocalNumber2" name="HomeTelephoneLocalNumber2" class="ppi-field-input-text" type="text" size="4" maxlength="4" />
                </div>
                <div id="ppi-fieldrequired-container-1" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Mobile Phone</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-MobileTelephoneAreaCode" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-MobileTelephoneAreaCode" name="MobileTelephoneAreaCode" class="ppi-field-input-text" type="text" size="3" maxlength="3" />
                </div>
                <div id="ppi-field-container-MobileTelephoneLocalNumber1" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-MobileTelephoneLocalNumber1" name="MobileTelephoneLocalNumber1" class="ppi-field-input-text" type="text" size="3" maxlength="3" />
                </div>
                <div id="ppi-field-container-MobileTelephoneLocalNumber2" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-MobileTelephoneLocalNumber2" name="MobileTelephoneLocalNumber2" class="ppi-field-input-text" type="text" size="4" maxlength="4" />
                </div>
                <div id="ppi-fieldrequired-container-2" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Preferred Contact Method</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-PreferredContactMethod" class="ppi-field-container" style={{display: "inline-block"}}>
                  <select id="ppi-data-PreferredContactMethod" name="PreferredContactMethod" class="ppi-field-select" size="1" required="">
                    <option value="">
                    </option>
                    <option value="E">Email</option>
                    <option value="H">Home Phone</option>
                    <option value="M">Mobile Phone</option>
                  </select>
                </div>
                <div id="ppi-fieldrequired-container-3" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <fieldset id="ppi-fieldset-5" class="ppi-fieldset" style={{borderRadius:"4px"}}>
        <legend class="ppi-legend">Emergency Contact</legend>
        <table id="ppi-table-5" class="ppi-table" cellpadding="4" cellspacing="0" style={{width: "100%", border: "0px"}}>
          <tbody>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Relationship</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Teleb_EmergencyRelation" class="ppi-field-container" style={{display: "inline-block"}}>
                  <select id="ppi-data-Teleb_EmergencyRelation" name="Teleb_EmergencyRelation" class="ppi-field-select" size="1" required="">
                    <option value="">
                    </option>
                    <option value="0">Spouse</option>
                    <option value="1">Mother</option>
                    <option value="2">Father</option>
                    <option value="3">Sister</option>
                    <option value="4">Brother</option>
                    <option value="5">Guardian</option>
                    <option value="6">Other</option>
                  </select>
                </div>
                <div id="ppi-fieldrequired-container-0" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Contact Name</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-Teleb_EmergencyContactName" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-Teleb_EmergencyContactName" name="Teleb_EmergencyContactName" class="ppi-field-input-text" type="text" size="20" maxlength="64" required="" />
                </div>
                <div id="ppi-fieldrequired-container-1" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
            <tr>
              <td class="ppi-td-label" style={{textalign: "right", width: "200px"}}>Contact Phone</td>
              <td class="ppi-td-field">
                <div id="ppi-field-container-EmergencyContactTelephoneAreaCode" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-EmergencyContactTelephoneAreaCode" name="EmergencyContactTelephoneAreaCode" class="ppi-field-input-text" type="text" size="3" maxlength="3" required="" />
                </div>
                <div id="ppi-field-container-EmergencyContactTelephoneLocalNumber1" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-EmergencyContactTelephoneLocalNumber1" name="EmergencyContactTelephoneLocalNumber1" class="ppi-field-input-text" type="text" size="3" maxlength="3" required="" />
                </div>
                <div id="ppi-field-container-EmergencyContactTelephoneLocalNumber2" class="ppi-field-container" style={{display: "inline-block"}}>
                  <input id="ppi-data-EmergencyContactTelephoneLocalNumber2" name="EmergencyContactTelephoneLocalNumber2" class="ppi-field-input-text" type="text" size="4" maxlength="4" required="" />
                </div>
                <div id="ppi-fieldrequired-container-2" class="ppi-fieldrequired-container" style={{display: "inline-block"}}>(Required)</div>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <div id="ppi-button-container" class="vertical-center" style={{textalign: "center"}}>
        <input id="ppi-button-submit" class="ppi-button" type="button" value="Submit Patient Information" />
      </div>
    </form>

  );
}