import React from 'react';
import {Fade, Box, Typography } from '@mui/material';
import HomePageImage from '../images/PhysicalTherapy.jpg';
import Hero from '../components/Hero';
import { SpacerLayout } from '../components/Layout';


function Home() {
  return (
    <Hero backgroundImage={HomePageImage}>
      <div style={{
        textAlign: 'center',
      }}
      >
        <Fade in={true} timeout={2500}>
        <Box
          sx={{
            margin: '0px 30px 0px 30px',
            padding: '5px',
            backgroundColor: 'rgba(52, 52, 52, .78)',
            maxWidth:'2000px',
            //backgroundColor: 'rgba(32, 55, 64, .78)'
          }}
        >
          <Typography
            //variant="h3"
            //color="#DFCFA7"
            sx={{
              color: 'secondary.main',
              margin: '15px 15px 15px 15px',
              textAlign: 'center',
              fontSize: { xs: 17, sm: 17, md: 22, lg: 28, xl: 33, xxl: 40, xxxl: 44 }
            }}
          >
            At Tensegrity Physical Therapy, we&#39;re here to help you on your journey towards a healthy, pain-free
            life. Whether you&#39;re a high-level athlete, weekend warrior, recovering from surgery, or just
            can&#39;t seem to get rid of that nagging pain that seems to keep recurring during your daily
            activities, Tensegrity is here to help. Having worked with all ages of clientele, from pediatrics
            through geriatrics, as well as all levels of human performance, from athletes wanting to return
            to sports without pain, to those simply wanting to be able to walk or regain balance, Tensegrity
            specializes in outpatient orthopedics treating a range of musculoskeletal and neuromuscular
            conditions.
          </Typography>
        </Box>
        </Fade>


      </div>
    </Hero>
  );
}

export default Home;
export const navIndex = 0;
export const notInNav = true;
export const isIndex = true;
export const layout = SpacerLayout;
