import { Typography, Box, Stack } from '@mui/material';
import React from 'react';

function Home() {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
      sx={{backgroundColor:'primary.main'}}
      >

      <Box
        padding='30px'
        sx={{
          flexBasis: 0, display: 'flex', flexGrow: 1, alignItems: 'center',
          height: { xs: '300px', sm: '300px', md: '300px', lg: '300px' },
          marginLeft: { xs: '0px', sm: '0px', md: '12%', lg: '12%' }
        }}
      >
        <Typography
          fontFamily="Montserrat"
          //color={theme.palette.secondary.main}
          sx={{
            textAlign: 'center',
            fontSize: { xs: 15, sm: 15, md: 18, lg: 18 },
            letterSpacing: '.1rem',
            margin: 'auto',
            color:'secondary.main'
          }}
        >
          Tensegrity Physical Therapy Marin PLLC<br />
          2020 4th Street Unit A<br />
          San Rafael, CA 94901 <br /><br />---<br /><br />
          justin@tensegritymarin.com<br />
          (415)726-7696<br />
        </Typography>
      </Box>


      <Box
        padding='30px'
        sx={{
          flexBasis: 0,
          flexGrow: 1,
          textAlign: 'center',
          alignItems: 'center',
          height: { xs: '300px', sm: '300px', md: '300px', lg: '300px' },
          marginRight: { xs: '0px', sm: '0px', md: '12%', lg: '12%' },
        }}
      >
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3145.085548679001!2d-122.54396009999999!3d37.9751328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085975be9929ce7%3A0xfb2e2378240cb79c!2s2020%204th%20St%20a%2C%20San%20Rafael%2C%20CA%2094901!5e0!3m2!1sen!2sus!4v1691623256394!5m2!1sen!2sus"
          width="80%" height="100%" style={{ border: "0", maxWidth: "400px" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </Box>

    </Stack>




  );
}

export default Home;
