import { Stack, Typography, Box } from '@mui/material';
import { SpacerLayout } from '../components/Layout';
import React from 'react';
import appleAppstoreIcon from '../images/appleAppStoreIcon.svg'
import googleAppstoreIcon from '../images/googleAppStoreIcon.svg'
import { Link } from 'react-router-dom';



function ExerciseLibrary() {
  return (

    <Box
    sx={{
      margin: 'auto',
      justifyContent: 'center',
      padding: '50px',
      alignItems: 'center',
      //height: '90vh',
      minHeight: 'calc(100vh - 406px)',
    }}>

      <Box
        sx={{ display: 'flex', margin:'auto', padding: '20px', alignItems: 'center', justifyContent: 'center', maxWidth: '1700px',}}
      >
        <Typography
          sx={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: { xs: 19, sm: 19, md: 22, lg: 25, xl: 28, xxl: 32, xxxl: 36},
            //position: { xs: 'static', sm: 'static', md: 'static', lg: 'fixed' },
            //minHeight: '400px',
            //bottom: '50vh'
          }}>
          Tensegrity Marin uses the Coach Catalyst app for a personalized exercise library for patients. <br/><br/> 
          After the initial session you will be emailed with the instructions on how to create an account on Coach Catalyst. <br/><br/> 
          Download the Coach Catalyst app with the links below!
        </Typography>
      </Box>


      <Stack
        direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'column-reverse', lg: 'row' }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '100px',
        }}
      >
        <Link target="_blank" to="https://play.google.com/store/apps/details?id=com.coachcatalyst.app&hl=en_US&gl=US">
          <img width='195px' height='100px' src={googleAppstoreIcon} />
        </Link>

        {/* <Link target="_blank" to="https://apps.apple.com/us/app/mycoach-by-coach-catalyst/id986569285"> */}
        <Link target="_blank" to="https://apps.apple.com/us/app/coach-catalyst/id1475622824">
          <img width='180px' height='60px' src={appleAppstoreIcon} />
        </Link>

      </Stack>

    </Box>


    // <Hero>
    //   <Typography
    //     variant="h3"
    //     textAlign="center"
    //     fontSize={{ xs: '1.5rem', sm: '2rem', md: '3rem' }}
    //     sx={{
    //       lineHeight: '1.5',
    //       letterSpacing: '1px'
    //     }}
    //   >
    //     Patient Information - Coming Soon
    //   </Typography>
    // </Hero>



  );
}

export default ExerciseLibrary;
export const layout = SpacerLayout;
export const navIndex = 3;
