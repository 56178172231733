import {Button, Modal, Box, Typography } from '@mui/material';
import {React } from 'react';
import { SpacerLayout } from '../components/Layout';
import { useState } from "react";

import PatientIntakeForm from '../components/PatientIntakeForm';


function PatientInformation() {
  const [open, setOpen] = useState(false);
  //const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpen = () => {setOpen(true)
    window['scrollTo']({top:0, behavior:'smooth'})
  };

  return (

    <Box
      sx={{
        margin: 'auto',
        justifyContent: 'center',
        padding: '50px',
        //height: '90vh',
        maxWidth: '1700px',
        minHeight: 'calc(100vh - 406px)',
      }}>


      <Box
      sx={{padding: '20px',}}>
        <Typography
          sx={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: { xs: 19, sm: 19, md: 22, lg: 25, xl: 28, xxl: 32, xxxl: 36},
            //position: { xs: 'static', sm: 'static', md: 'static', lg: 'fixed' },
            //minHeight: '400px',
            bottom: '50vh'
          }}>
          If you are a new patient, please open the Patient Intake Form below and submit to register. <br /><br /> If you are already an existing patient, please contact us at justin@tensegritymarin.com for any requests regarding patient information. <br />
        </Typography> 
      </Box>


      <Box
        sx={{
          margin: '100px',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}>

        <Button 
          variant='contained' 
          onClick={handleOpen}
          size='large'
          >Patient Intake Form</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ 
            //backdropFilter: "blur(1px)", 
          //backgroundColor: "rgb(255, 255, 255)", 
          position: 'absolute',
          overflow: 'auto',
          height: '100vh',
          display: 'block',
      
        }}
        >
          <Box
            sx={{
              margin: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '40px',
              marginBottom: {xs:'100px', sm: '100px', md: '40px'},
              backgroundColor: 'secondary.main',
              width: '85%',
              maxWidth: '850px'
              //maxHeight: '100%',
            }}>

            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                margin: 'auto',
                //minWidth: '380px',
                justifyContent: 'center',
                fontFamily: 'fontFamily',
                //width: { md: '100vw', lg: '50vw' }
              }}>
              {PatientIntakeForm()}
            </Box>

            <Box
              sx={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop:'30px'
              }}>
              <Button 
              variant='contained'
              onClick={handleClose}
              >Close</Button>
            </Box>

          </Box>
        </Modal>

      </Box>


    </Box>


  );
}

export default PatientInformation;
export const layout = SpacerLayout;
export const navIndex = 4;
