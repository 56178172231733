import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { SpacerLayout } from '../components/Layout';



function accordionElement(Header, Body) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1d-content" 
        sx={{
          backgroundColor: "secondary.main"
        }}>
        <Typography
        margin="0px 20px 0px 20px"
        textAlign="left"
        sx={{
          fontWeight:"bold",
          fontSize: { xs: "1rem", sm: "1rem", md: "1.2rem", lg: "1.25rem", xl: '1.3rem', xxl: '1.4rem', xxxl: '1.7rem' }
        }}>{Header}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "secondary.main"
        }}>
        <Typography
        margin="0px 20px 0px 20px"
        sx={{
          fontSize: { xs: "1rem", sm: "1rem", md: "1.2rem", lg: "1.25rem", xl: '1.3rem', xxl: '1.4rem', xxxl: '1.7rem' }
        }}>
          {Body}
        </Typography>
      </AccordionDetails>
    </Accordion>

  );
}



function FAQ() {
  return (
    <Box
      sx={{
        display: 'grid',
        backgroundColor: "secondary.main",
        minHeight: 'calc(100vh - 406px)',
        //maxWidth: '75%',
        //justifyContent: 'center',
        //alignItems: 'center',
      }}>
        <Box
        sx={{
        //width:'100vw',
        maxWidth:'2000px',
        marginLeft:'auto',
        marginRight:'auto',
        justifyContent: 'center',
        //alignItems: 'center',
      }}
        >
      <Typography
        variant="h3"
        textAlign="center"
        fontSize={{ xs: '1.5rem', sm: '2rem', md: '3rem' }}
        margin="30px"
        sx={{
          lineHeight: '1.5',
          letterSpacing: '1px'
        }}
      >
        Frequently Asked Questions
      </Typography>
        {accordionElement("Do you accept insurance?","I am an out-of-network provider and do not accept insurance. Depending on your plan, you may be eligible for full or partial reimbursement from your insurance company. I will happily provide you a \"superbill\" to submit to your insurer for out-of-network reimbursement upon request.")}
        {accordionElement("What are your fees?","My rate is $200 for an initial evaluation and $150 for follow-up visits, which are both 50-minute sessions.")}
        {accordionElement("How long are your physical therapy treatments?","Both initial evaluations and follow-up visits are 50 minutes.")}
        {accordionElement("What does Tensegrity mean?","Tensegrity, or biotensegrity, is a concept that describes the structural and mechanical principles governing living organisms, emphasizing the interconnectedness and balance between tension and compression forces. The biotensegrity concept recognizes that all living structures result from interactions between some basic principles of self-organization, or rules of physics, and that these apply to everything from the smallest of molecules to the complete organism. This structural organization enables the efficient distribution of forces, optimal load-bearing capacity, and the ability to withstand mechanical stresses while maintaining flexibility and stability. This comprehensive perspective provides insights into the dynamic nature of living systems and their integrated functionality. This model is the foundation of our approach to physical therapy.")}
        {accordionElement("Why don’t you accept insurance?","By opting for a direct pay system rather than dealing with insurance, he dedicates his time and expertise to providing exceptional care rather than navigating insurance bureaucracy. Drawing from his extensive experience in insurance-based practices, Dr. Justin understands the immense effort required to pursue insurance reimbursements, contend with denied claims, and manage the complexities of insurance-related tasks. Unlike conventional insurance-driven clinics, Tensegrity PT focuses on optimizing patient outcomes while circumventing the challenges posed by dwindling reimbursements and the balance between profitability and superior patient treatment.")}
    </Box>
    </Box>
  );
}

export default FAQ;
export const navIndex = 5;
export const layout = SpacerLayout;

