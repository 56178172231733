import * as React from 'react';

export default function Hero({ backgroundImage, children }) {
  return (
    <div style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      //backgroundColor: '#DFCFA7',
      height: '100vh',
      width: '100%',
      backgroundPosition: '60% center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      {children}
    </div>
  );
}
