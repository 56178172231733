import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, Box, Typography } from '@mui/material';
import * as React from 'react';
import Footer from './Footer';
import theme from '../theme';
import {FixedNavbar} from '@riderz-labs/crab-shack';
import logo from '../images/TensegrityIcon.svg';
// import logo from '../images/Tensegrity.jpg'
// import '@fontsource/audiowide'

function ImgLogo ({ imgSrc }: { imgSrc: string }): JSX.Element {
  return (
      <img
        // style={{
        //   maxWidth: '100%',
        //   maxHeight: '60px',
        //   display: 'block'
        // }}
        src={imgSrc}
        alt="logo"
        height="70px"
        // style={{padding: '5px 0px 0px 0px'}}
      />
  )
}


export const tensegrityLogo = (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    fontSize: {
      xs: '1rem',
      sm: '1.5rem'
    }
  }}>
    <Box sx={{
      width: {
        xs: '50px',
        sm: 'auto'
      }
    }}>
      
      
      <ImgLogo imgSrc={logo}/>
    </Box>

    <Typography
        variant="h1"
        fontSize="inherit"
        //color="#DFCFA7"
        //fontFamily="Nexa"
        marginLeft="15px"
        fontWeight="500"
        sx={{
          fontSize:25,
          textAlign: {xs: 'center', sm: 'center', md: 'left', lg: 'left'} ,
          color: 'secondary.main'
        }}
      >
        Tensegrity Physical Therapy Marin
      </Typography>
  </Box>
)

function BaseLayout ({ pages, addNavSpacer=false, backgroundColor='pallete.main' }: { pages: any, addNavSpacer?: boolean, backgroundColor?: string }): JSX.Element {
  
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <FixedNavbar addSpacer={addNavSpacer} sx={{ backgroundColor: { backgroundColor } }} logoContents={tensegrityLogo} pages={pages} activeColor={'white'} inactiveColor={'#DFCFA7'} />
        <Outlet/>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export function NoSpacerLayout  ({ pages }: { pages: any }): JSX.Element {
  return (
    <BaseLayout pages={pages} //backgroundColor='#000000' 
    />  
    //<BaseLayout pages={pages} backgroundColor='#203740' />
    );
}

export function SpacerLayout  ({ pages }: { pages: any }): JSX.Element {
  return (
    <BaseLayout pages={pages} addNavSpacer //backgroundColor='#000000'
    />  
    //<BaseLayout pages={pages} addNavSpacer backgroundColor='#203740'/>
    );
}


