import { Fade, Typography, Box, Stack } from '@mui/material';
import React from 'react';
import Justin from '../images/Justin.jpg';
import { SpacerLayout } from '../components/Layout';
import { useState } from "react";
import { InView } from 'react-intersection-observer';


// textBoxHeader xs: '2rem', sm: '2rem', md: '2.5rem', lg: '2.8rem', xl: '4rem' }"
// textBoxBody xs: 19, sm: 19, md: 24, lg: 26, xl: 38 

function MainTextBox() {

  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 105px)',
        //minHeight: '91vh',
        display: 'flex',
        //minWidth: '100vw',
        maxWidth: '1700px',
        margin: 'auto',
      }}>
    <Box
      padding={{ xs: "30px", sm: "30px", md: "50px", lg: "50px" }}
      sx={{ margin: 'auto', 
      //backgroundColor: 'secondary.main'
     }}
    >
      <Typography
        variant="h3"
        sx={{
          marginBottom: '30px',
          color: 'primary.main',
          textAlign: "center",
          fontSize: { xs: 24, sm: 24, md: 32, lg: 34, xl: 36, xxl: 42, xxl: 54 },
          lineHeight: '1.5',
          fontWeight: "medium"
        }}
      >
        Tensegrity Physical Therapy is a private outpatient practice in Marin that specializes in orthopedics and sports medicine. We prioritize patient experience above all else and pride ourselves in offering longer one-on-one treatments with Dr. Justin to help patients achieve their human performance goals.
      </Typography>

      <Typography
        variant="body1"
        margin={{ xs: '0px 0px 0px 0px', sm: '0px 0px 0px 0px', md: '0px 0px 0px 0px', lg: '0px 0px 0px 0px' }}
        sx={{
          //color: '#203740',
          fontSize: { xs: 24, sm: 24, md: 32, lg: 34, xl: 36, xxl: 42, xxxl: 54 },
          textAlign: "center",
          lineHeight: '1.5'
        }}
      >
        <br />Specialties include:<br />
      </Typography>
      <Stack
        direction="row">
        <Typography
          margin={{ xs: "inherit", sm: "inherit", md: "auto", lg: "auto"}}
          //minWidth="170px"
          width='50%'
          sx={{
            //color: '#203740',
            fontSize: { xs: 16, sm: 16, md: 22, lg: 28, xl: 35, xxl: 38},
            textAlign: "left",
            lineHeight: '1.5'
          }}>
          &#8226; Return to sport &amp; run/gait analysis<br />
          &#8226; Adolescent and pediatric injuries<br />
          &#8226; Lumbar spine and neck treatment<br /></Typography>
        <Typography 
          margin={{ xs: "inherit", sm: "inherit", md: "auto", lg: "auto"}}
          //minWidth="170px"
          width='50%'
          sx={{
          //color: '#203740',
          fontSize: { xs: 16, sm: 16, md: 22, lg: 28, xl: 34, xxl: 38},
          textAlign: "left",
          lineHeight: '1.5'
        }}>        
          &#8226; Advanced soft tissue and joint mobilization<br />
          &#8226; Postural restoration<br />
          &#8226; Movement analysis<br /></Typography>
      </Stack>
    </Box>
    </Box>
  );
}



function LeftSideTextBox(Header, Body) {
  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        justifyContent: 'center',
        minHeight: '100vh',
        display: 'flex',
      }}>
      <Box
        padding={{ xs: "30px", sm: "30px", md: "50px", lg: "50px" }}
        sx={{ margin: 'auto', maxWidth: '1800px' }}
      >
        <Typography
          variant="h3"
          sx={{
            marginBottom: '30px',
            color: 'primary.main',
            textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' },
            fontSize: { xs: '2rem', sm: '2rem', md: '2.7rem', lg: '3rem', xl: '3.5rem', xxl: '4rem' },
            lineHeight: '1.5'
          }}
        >
          {Header}
        </Typography>

        <Typography
          variant="body1"
          margin={{ xs: '0px 0px 0px 0px', sm: '0px 0px 0px 0px', md: '0px 0px 0px 0px', lg: '0px 0px 0px 0px' }}
          sx={{
            //color: '#203740',
            fontSize: { xs: 19, sm: 19, md: 24, lg: 26, xl: 34, xxl: 36, xxxl: 46 },
            textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' },
            lineHeight: '1.5'
          }}
        >
          {Body}
        </Typography>
      </Box>
    </Box>
  );
}



function RightSideTextBox(Header, Body) {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        justifyContent: 'center',
        minHeight: '100vh',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          padding: { xs: "30px", sm: "30px", md: "50px", lg: "50px" },
          maxWidth: '1800px',
          margin: 'auto',
          backgroundColor: "primary.main"
        }}>
        <Typography
          variant="h3"
          align="right"
          sx={{
            marginBottom: '30px',
            color: "secondary.main",
            fontSize: { xs: '2rem', sm: '2rem', md: '2.7rem', lg: '3rem', xl: '3.5rem', xxl: '4rem' },
            textAlign: { xs: 'center', sm: 'center', md: 'right', lg: 'right' },
            lineHeight: '1.5'
          }}
        >
          {Header}
        </Typography>
        <Typography
          align="right"
          variant="body2"
          margin={{ xs: '0px 0px 0px 0px', sm: '0px 0px 0px 0px', md: '0px 0px 0px 0px', lg: '0px 0px 0px 0px' }}
          sx={{
            color: "secondary.main",
            fontSize: { xs: 19, sm: 19, md: 24, lg: 26, xl: 34, xxl: 36, xxxl: 46 },
            textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' },
            lineHeight: '1.5'
          }}
        >
          {Body}
        </Typography>

      </Box>
    </Box>

  );
}



function RightSideTextBoxWithImage(Header, Body1, Body2, img) {
  return (
    <Box
      //backgroundColor='#203740'
      padding={{ xs: "30px", sm: "30px", md: "50px", lg: "50px" }}
      sx={{
        backgroundColor: "primary.main",
        justifyContent: 'center',
        minHeight: '100vh',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          paddingTop: "20px",
          maxWidth: '1800px',
          margin: 'auto'
        }}>
        <Typography
          variant="h3"
          align="right"
          sx={{
            marginBottom: '30px',
            color: "secondary.main",
            fontSize: { xs: '2rem', sm: '2rem', md: '3rem', lg: '3rem' },
            textAlign: { xs: 'center', sm: 'center', md: 'right', lg: 'right' },
            lineHeight: '1.5'
          }}
        >
          {Header}
        </Typography>

        <Stack
          direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row', lg: 'row' }}
          sx={{
            marginBottom: '20px',
            alignItems: { xs: 'center', sm: 'center', md: 'right', lg: 'right' }
          }}>

          <Box>
            <Typography
              align="left"
              variant="body2"
              sx={{
                fontSize: { xs: 19, sm: 19, md: 23, lg: 26, xl: 30, xxl: 38, xxxl: 44},
                fontWeight: 'bold',
                color: "secondary.main",
                marginTop: '10px',
                marginBottom: '10px',
                textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' },
                lineHeight: '1.5'
              }}
            >
              {Body1}
            </Typography>

            <Typography
              align="left"
              variant="body2"
              sx={{
                fontSize: { xs: 19, sm: 19, md: 23, lg: 26, xl: 30, xxl: 38, xxxl: 44},
                color: "secondary.main",
                textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' },
                lineHeight: '1.5'
              }}
            >
              {Body2}
            </Typography>
          </Box>

          <Box
            component="img"
            src={img}
            sx={{
              maxWidth: '320px',
              marginLeft: { xs: '0px', sm: '0px', md: '20px', lg: '20px' },
              // alignItems: {xs: 'center', sm: 'center', md: 'right', lg: 'right'}
            }}
          >
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}


function LeftSideTextBoxWithImage(Header, Body1, Body2, img) {
  return (
    <Box
      //backgroundColor='#DFCFA7'
      padding={{ xs: "30px", sm: "30px", md: "50px", lg: "50px" }}
      sx={{
        backgroundColor: 'secondary.main',
        justifyContent: 'center',
        minHeight: '100vh',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          paddingTop: "20px",
          maxWidth: '1800px',
          margin: 'auto',

        }}>
        <Typography
          variant="h3"
          align="left"
          sx={{
            marginBottom: '30px',
            color: 'primary.main',
            //fontSize: { xs: '2rem', sm: '2rem', md: '3rem', lg: '3rem' },
            fontSize: { xs: '2rem', sm: '2rem', md: '2.7rem', lg: '3rem', xl: '3.5rem', xxl: '4rem' },
            textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left' },
            lineHeight: '1.5'

          }}
        >
          {Header}
        </Typography>

        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
          sx={{
            marginBottom: '20px',
            alignItems: { xs: 'center', sm: 'center', md: 'center', lg: 'right' }
          }}>
          <Box
            component="img"
            src={img}
            sx={{
              maxHeight: { xs: '400px', sm: '400px', md: '580px', lg: '580px' },
              marginRight: { xs: '0px', sm: '0px', md: '0px', lg: '50px' },
            }}
          >
          </Box>

          <Box>
            <Typography
              align="left"
              variant="body2"
              sx={{
                //fontSize: { xs: 19, sm: 19, md: 24, lg: 26, xl: 30},
                fontSize: { xs: 19, sm: 19, md: 23, lg: 26, xl: 30, xxl: 33, xxxl: 36},
                fontWeight: 'bold',
                color: 'primary.main',
                marginTop: '10px',
                marginBottom: '10px',
                textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left' },
                lineHeight: '1.5'
              }}
            >
              {Body1}
            </Typography>

            <Typography
              align="left"
              variant="body2"
              sx={{
                fontSize: { xs: 19, sm: 19, md: 23, lg: 26, xl: 30, xxl: 33, xxxl: 36},
                color: 'primary.main',
                textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left' },
                lineHeight: '1.5'
              }}
            >
              {Body2}
            </Typography>
          </Box>


        </Stack>
      </Box>
    </Box>
  );
}



function fadeTransitionFast(inputBox) {
  let [active, setActive] = useState(false);
  return (
    <InView
      onChange={(isVisible) => {
        setActive(isVisible);
      }}
    >
      <Fade in={active} timeout={1500}>
        {inputBox}
      </Fade>
    </InView>
  );
}

function fadeTransitionSlow(inputBox) {
  let [active, setActive] = useState(false);
  return (
    <InView
      onChange={(isVisible) => {
        setActive(isVisible);
      }}
    >
      <Fade in={active} timeout={3000}>
        {inputBox}
      </Fade>
    </InView>
  );
}



function About() {



  return (

    <Stack
      backgroundColor="#DFCFA7"
      sx={{
        display: 'flex',
        direction: 'column',
        //minHeight: '80vh',
      }}
    >

      {fadeTransitionSlow(MainTextBox())}

      {fadeTransitionSlow(RightSideTextBox("Longer One-On-One Treatments With Your Doctor of Physical Therapy, Every Visit", "To ensure that you receive the highest level of care possible, each treatment you will spend 50 minutes of high quality, individualized, one-on-one care from a doctor of physical therapy. You will never be treated by a rehab aid or PT assistant."))}

      {fadeTransitionSlow(LeftSideTextBox("The Tensegrity Difference", "Tensegrity Physical Therapy recognizes the interconnectedness of the body's systems. With our comprehensive expertise and training, we understand that the source of your pain may not always correlate with its apparent location. Beyond symptom relief, our mission is to uncover and address underlying weaknesses, fortifying your body's resilience and reducing the risk of future injuries."))}



      {fadeTransitionSlow(RightSideTextBox("What is Tensegrity?", "Tensegrity, or biotensegrity, is a concept that describes the structural and mechanical principles governing living organisms, emphasizing the interconnectedness and balance between tension and compression forces. The biotensegrity concept recognizes that all living structures result from interactions between some basic principles of self-organization, or rules of physics, and that these apply to everything from the smallest of molecules to the complete organism. This structural organization enables the efficient distribution of forces, optimal load-bearing capacity, and the ability to withstand mechanical stresses while maintaining flexibility and stability. This comprehensive perspective provides insights into the dynamic nature of living systems and their integrated functionality. This model is the foundation of our approach to physical therapy."))}


      {fadeTransitionSlow(LeftSideTextBoxWithImage("Meet The Owner", "Justin Newcomer, PT, DPT", "Justin Newcomer is the founder and owner of Tensegrity Physical Therapy Marin. He brings extensive experience in a variety of rehabilitation settings, including outpatient and inpatient, and provides a comprehensive model of therapy in which the body is treated as a whole, rather than individual parts. His well-rounded skill set in manual therapy and exercise-based rehabilitation helps patients achieve their goals of improving movement related to dysfunction, pain and sports performance. He earned his doctorate in physical therapy from the University of St. Augustine for Health Sciences and completed his undergraduate studies at the University of Oregon. When not treating patients, Justin loves hiking, mountain biking, golf, snowboarding and traveling with his wife and son.", Justin))}


    </Stack>

  );
}


export default About;
export const navIndex = 1;
export const layout = SpacerLayout;
