import React from 'react';
import ReactDOM from 'react-dom/client';
import { WebpackPageRouter } from '@riderz-labs/crab-shack';
import { NoSpacerLayout } from './components/Layout.tsx';


export default function App() {
  const webpackContext = require.context(
    './pages',
    true,
    /\.jsx?$/,
  );

  return (
    <React.StrictMode>
      <WebpackPageRouter routerType="browser" webpackContext={webpackContext} defaultLayoutComponent={NoSpacerLayout} />
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
